import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { BgImage } from "gbimage-bridge";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import FeaturedProperties from "../components/featured-properties";
import Layout from "../components/layout";
import PreferToTalk from "../components/prefer-to-talk";
import SafeHtmlParser from "../components/safe-html-parser";
import VerticalListingsPropertyCard from "../components/vertical-listings-property-card";

const AreaGuide = ({
	data: { post, site, properties, heroInterior, default1, default2 },
}) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Area Guides",
				item: {
					url: `${siteUrl}/area-guides`,
					id: `${siteUrl}/area-guides`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.seoFields?.metaTitle}`,
				item: {
					url: `${siteUrl}/area-guides/${post.slug}`,
					id: `${siteUrl}/area-guides/${post.slug}`,
				},
			},
		],
	};
	const heroImage = post.areaGuideFields?.image1 || heroInterior;
	const image1 =
		post.areaGuideFields?.image1?.localFile.childImageSharp.gatsbyImageData;
	const image2 =
		post.areaGuideFields?.image2?.localFile.childImageSharp.gatsbyImageData ||
		default1.localFile.childImageSharp.gatsbyImageData;
	const image3 =
		post.areaGuideFields?.image3?.localFile.childImageSharp.gatsbyImageData ||
		default2.localFile.childImageSharp.gatsbyImageData;
	const pluginImage = getImage(image1);
	const pluginImage2 = getImage(image2);
	const pluginImage3 = getImage(image3);

	const featuredProperty1 = properties.edges?.filter(
		(prop) => prop.node.fixedId === post.areaGuideFields.nameOfProperty1
	);
	const featuredProperty2 = properties.edges?.filter(
		(prop) => prop.node.fixedId === post.areaGuideFields.nameOfProperty2
	);
	const featuredProperty3 = properties.edges?.filter(
		(prop) => prop.node.fixedId === post.areaGuideFields.nameOfProperty3
	);

	const officesTemp = [
		featuredProperty1[0],
		featuredProperty2[0],
		featuredProperty3[0],
	];

	const officestemp2 = officesTemp.filter((e) => e?.node?.fixedId > 0);

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post.seoFields?.metaTitle}
				description={post.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/area-guides/${post.slug}`,
					title: `${post.seoFields?.opengraphTitle}`,
					description: `${post.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${post.seoFields?.image?.localFile.publicURL}`,
							width: `${post.seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${post.seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${post.seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<section className="bg-primary position-relative bg-opacity-10 py-4 py-lg-5 mb-xl-5 mb-4">
				{post.areaGuideFields?.image1 !== null && (
					<div
						style={{ zIndex: 1 }}
						className="w-100 h-100 position-absolute top-0 start-0"
					>
						{" "}
						<BgImage
							style={{ zIndex: 1 }}
							className="h-100 w-100 position-relative"
							image={pluginImage}
						></BgImage>
						<div
							style={{ zIndex: 2, opacity: 0.4 }}
							className="h-100 w-100 bg-black position-absolute start-0 top-0"
						></div>
					</div>
				)}
				<Container style={{ zIndex: 3 }} className="position-relative">
					<Row>
						<Col className="text-center">
							<p
								className={`${
									post.areaGuideFields?.image1 !== null
										? "text-white"
										: "text-med-grey"
								} text-uppercase fw-bold`}
							>
								{post.areaGuideFields.smallHeading}
							</p>
							<h1
								className={`${
									post.areaGuideFields?.image1 !== null ? "text-white" : ""
								}`}
							>
								{post.title}
							</h1>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-lg-5 ">
				<Container>
					<Row className="gx-6">
						<Col lg={6}>
							<SafeHtmlParser htmlContent={post.areaGuideFields.description} />

							{post.areaGuideFields?.officeSpaceText !== null && (
								<div>
									<h2 className="mt-4 fs-3">Office Space</h2>
									<SafeHtmlParser
										htmlContent={post.areaGuideFields?.officeSpaceText}
									/>
								</div>
							)}
						</Col>
						<Col md={6} lg={6} className="px-4 d-none d-lg-block">
							<PreferToTalk
								title={`Find out more about offices in ${post.title}`}
								message="Speak to our experts about your requirements and get a personalised shortlist."
							/>
						</Col>
						{post.areaGuideFields?.localCompaniesText !== null && (
							<Col xs={12}>
								{" "}
								<h2 className="mt-lg-4 mt-2 fs-3">Local Companies</h2>
								<SafeHtmlParser
									htmlContent={post.areaGuideFields?.localCompaniesText}
								/>
							</Col>
						)}
						<Col className="px-4 mb-4 d-lg-none">
							<PreferToTalk
								title={`Find out more about offices in ${post.title}`}
								message="Speak to our experts about your requirements and get a personalised shortlist."
							/>
						</Col>
					</Row>
				</Container>
			</section>
			{post.areaGuideFields?.pricing.fromPerDeskmonth !== null && (
				<section>
					<Container>
						<Row>
							<Col>
								<hr className="my-1 " />

								<>
									<h2 className="fs-1 text-center text-lg-start my-4">
										Rental guidelines
									</h2>
									<Row>
										<Col xl={6}>
											<blockquote>
												<p className="mb-1 fs-2">Serviced offices</p>

												<h2 className={`mt-1 fs-3 fw-bold display-${5}`}>
													£
													{post.areaGuideFields?.pricing.fromPerDeskmonth.toLocaleString(
														"en-UK"
													)}{" "}
													- £
													{post.areaGuideFields?.pricing.toPerDeskmonth.toLocaleString(
														"en-UK"
													)}{" "}
													per desk / month
												</h2>
											</blockquote>
										</Col>
										<Col xl={6}>
											<blockquote className="mt-4 mt-xl-0">
												<p className="mb-1 fs-2">Flex lease</p>

												<h2 className={`mt-1 fs-3 fw-bold display-${5}`}>
													£
													{post.areaGuideFields?.pricing.fromPerSquareFoot.toLocaleString(
														"en-UK"
													)}{" "}
													- £
													{post.areaGuideFields?.pricing.toPerSquareFoot.toLocaleString(
														"en-UK"
													)}{" "}
													per square foot
												</h2>
											</blockquote>
										</Col>
									</Row>
								</>
							</Col>
						</Row>
					</Container>
				</section>
			)}
			<section className="py-lg-5 pt-4 pb-5 pb-md-4">
				<Container>
					<Row className="justify-content-center">
						<Col lg={8} className="text-center">
							<Row className="mt-4 justify-content-center">
								{" "}
								<Col md={6}>
									<Link
										className="w-100 btn fw-bold rounded-pill text-white bg-dark-blue  py-3 me-5 mb-4"
										to="/contact-us"
									>
										GET IN TOUCH
									</Link>
								</Col>
								{post.areaGuideFields?.searchButtonText !== null && (
									<Col md={6}>
										<a
											className="w-100 btn fw-bold rounded-pill border-1 border-dark-blue text-darker-blue  py-3"
											href={post.areaGuideFields?.searchButtonLink}
										>
											{post.areaGuideFields?.searchButtonText}
										</a>
									</Col>
								)}
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<div style={{ height: "250px" }} className="w-100  ">
					{" "}
					<BgImage className="h-100 w-100" image={pluginImage2}></BgImage>
				</div>
			</section>
			{(post.areaGuideFields?.hasMembersBars !== null ||
				post.areaGuideFields?.hasRestaurants !== null ||
				post.areaGuideFields?.hasBars !== null) && (
				<section className="pt-lg-5 pt-4 ">
					<Container>
						<Row>
							<Col>
								<h2 className="fs-1 text-center text-lg-start mb-4 mb-lg-6">
									Our recommendations
								</h2>
							</Col>
						</Row>
						<Row className="gx-3 gy-lg-5 gy-4 justify-content-center">
							{post.areaGuideFields?.hasMembersBars !== null && (
								<Col lg={6} xl={4}>
									<h3 className="mb-3 fs-4">Members Bars</h3>{" "}
									{post.areaGuideFields?.membersBars.map((bar, i) => (
										<div className="p-3 mb-3 bg-primary bg-opacity-10 rounded">
											<h4 className="fs-5">{bar.name}</h4>
											{bar?.address !== null && (
												<a
													href={`https://www.google.com/maps/search/${bar.address}`}
													target="_blank"
													rel="noreferrer"
													className="pb-1 black-link d-block mb-0"
												>
													{bar.address}
												</a>
											)}
											{bar?.website !== null && (
												<a
													className="black-link mb-0 pb-0"
													target="_blank"
													rel="noreferrer"
													href={`http://${bar.website}`}
												>
													{bar.website}
												</a>
											)}
										</div>
									))}
								</Col>
							)}
							{post.areaGuideFields?.hasRestaurants !== null && (
								<Col lg={6} xl={4}>
									<h3 className="mb-3 fs-4">Restaurants</h3>{" "}
									{post.areaGuideFields?.restaurants.map((bar, i) => (
										<div className="p-3 mb-3 bg-primary bg-opacity-10 rounded">
											<h4 className="fs-5">{bar.name}</h4>
											{bar?.address !== null && (
												<a
													href={`https://www.google.com/maps/search/${bar.address}`}
													target="_blank"
													rel="noreferrer"
													className="pb-1 black-link d-block mb-0"
												>
													{bar.address}
												</a>
											)}
											{bar?.website !== null && (
												<a
													className="black-link mb-0 pb-0"
													target="_blank"
													rel="noreferrer"
													href={`http://${bar.website}`}
												>
													{bar.website}
												</a>
											)}
										</div>
									))}
								</Col>
							)}

							{post.areaGuideFields?.hasBars !== null && (
								<Col lg={6} xl={4}>
									<h3 className="mb-3 fs-4">Bars</h3>{" "}
									{post.areaGuideFields?.bars.map((bar, i) => (
										<div className="p-3 mb-3 bg-primary bg-opacity-10 rounded">
											<h4 className="fs-5">{bar.name}</h4>
											{bar?.address !== null && (
												<a
													href={`https://www.google.com/maps/search/${bar.address}`}
													target="_blank"
													rel="noreferrer"
													className="pb-1 black-link d-block mb-0"
												>
													{bar.address}
												</a>
											)}
											{bar?.website !== null && (
												<a
													className="black-link mb-0 pb-0"
													target="_blank"
													rel="noreferrer"
													href={`http://${bar.website}`}
												>
													{bar.website}
												</a>
											)}
										</div>
									))}
								</Col>
							)}
						</Row>
					</Container>
				</section>
			)}
			{post.areaGuideFields?.nameOfProperty1 !== null && (
				<section className="pt-lg-5 pt-4 ">
					<Container>
						<Row>
							<Col>
								<h2 className="fs-1 text-center pb-lg-7 pb-5 ">
									Some popular properties in the area include
								</h2>
							</Col>
						</Row>
						<Row className="g-4">
							<FeaturedProperties
								colWidthXL={4}
								colWidthLG={6}
								CardType={VerticalListingsPropertyCard}
								properties={officestemp2}
							/>
						</Row>
					</Container>
				</section>
			)}
			<section className="my-lg-5 my-4">
				<div style={{ height: "250px" }} className="w-100  ">
					{" "}
					<BgImage className="h-100 w-100" image={pluginImage3}></BgImage>
				</div>
			</section>
			{post.areaGuideFields?.transportText !== null && (
				<section className="pb-lg-5 pb-4 ">
					<Container>
						<Row>
							<Col lg={8}>
								<h2 className="fs-3">Transport</h2>
								<SafeHtmlParser
									htmlContent={post.areaGuideFields?.transportText}
								/>
							</Col>
						</Row>
					</Container>
				</section>
			)}
			{(post.areaGuideFields?.hasThingsToDo !== null ||
				post.areaGuideFields?.hasGyms !== null) && (
				<section className="pb-lg-5 pb-4 ">
					<Container>
						<Row className="gx-3 gy-lg-5 gy-4 justify-content-center">
							{post.areaGuideFields?.hasThingsToDo !== null && (
								<Col lg={12} xl={8}>
									<h3 className="mb-3 fs-4">Things to do & see</h3>{" "}
									{post.areaGuideFields?.thingsToDo.map((bar, i) => (
										<div className="p-3 mb-3 bg-primary bg-opacity-10 rounded">
											<h4 className="fs-5">{bar.name}</h4>
											{bar?.address !== null && (
												<a
													href={`https://www.google.com/maps/search/${bar.address}`}
													target="_blank"
													rel="noreferrer"
													className="pb-1 black-link d-block mb-0"
												>
													{bar.address}
												</a>
											)}
											{bar?.description !== null && (
												<p className="pb-0 mb-0">{bar?.description}</p>
											)}
										</div>
									))}
								</Col>
							)}
							{post.areaGuideFields?.hasGyms !== null && (
								<Col lg={6} xl={4}>
									<h3 className="mb-3 fs-4">Gyms</h3>{" "}
									{post.areaGuideFields?.gyms.map((bar, i) => (
										<div className="p-3 mb-3 bg-primary bg-opacity-10 rounded">
											<h4 className="fs-5">{bar.name}</h4>
											{bar?.address !== null && (
												<a
													href={`https://www.google.com/maps/search/${bar.address}`}
													target="_blank"
													rel="noreferrer"
													className="pb-1 black-link d-block mb-0"
												>
													{bar.address}
												</a>
											)}
											{bar?.website !== null && (
												<p className="mb-0 pb-0">
													<a
														className="black-link pb-0 mb-0"
														target="_blank"
														rel="noreferrer"
														href={`http://${bar.website}`}
													>
														{bar.website}
													</a>
												</p>
											)}
										</div>
									))}
								</Col>
							)}
						</Row>
					</Container>
				</section>
			)}
		</Layout>
	);
};

export default AreaGuide;

export const pageQuery = graphql`
	query AreaGuideById($id: String!) {
		post: wpAreaGuide(id: { eq: $id }) {
			seoFields {
				metaTitle
				metaDescription
				opengraphDescription
				opengraphTitle
				productSchema
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
						publicURL
					}
				}
			}
			title
			slug
			areaGuideFields {
				nameOfProperty1
				nameOfProperty2
				nameOfProperty3
				smallHeading
				description
				localCompaniesText
				officeSpaceText
				transportText
				pricing {
					fromPerDeskmonth
					toPerDeskmonth
					fromPerSquareFoot
					toPerSquareFoot
				}
				image1 {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
				image2 {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
				image3 {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
				hasMembersBars
				hasRestaurants
				hasBars
				hasGyms
				hasThingsToDo
				membersBars {
					name
					website
					address
				}
				restaurants {
					address
					name
					website
				}
				gyms {
					address
					name
					website
				}
				bars {
					address
					name
					website
				}
				thingsToDo {
					address
					description
					name
				}
				searchButtonLink
				searchButtonText
			}
		}

		properties: allAirtableProperties(filter: { live: { eq: true } }) {
			edges {
				node {
					addressLineOne
					desksFrom
					live
					desksTo
					subwayStation1
					subwayStation1DistanceKm
					subwayStation1DurationMins
					subwayStation2
					subwayStation2DistanceKm
					subwayStation2DurationMins
					subwayStation3
					subwayStation3DistanceKm
					subwayStation3DurationMins
					type
					rentPM
					city
					airtableId
					locationLatitude
					locationLongitude
					name
					services
					amenities
					provider
					features
					fixedId
					description
					photos
					floorsAvailable {
						rentPM
						desksTo
						desksFrom
						name
						sqFt
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}

		heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		default1: wpMediaItem(title: { eq: "Area Guide Image 2" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		default2: wpMediaItem(title: { eq: "Area Guide Image 3" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
	}
`;
