import React from "react";
import parse from "html-react-parser";

const SafeHtmlParser = ({ htmlContent }) => {
	let correctedHtml = htmlContent.replace(
		/className\s*=\s*(".*?"|'.*?'|[^"'\s>]+)/g,
		"class=$1"
	);

	// Only import and use DOMPurify if `window` is defined, indicating a browser environment
	if (typeof window !== "undefined") {
		const DOMPurify = require("dompurify");
		correctedHtml = DOMPurify.sanitize(correctedHtml);
	}

	const content = parse(correctedHtml);

	return <>{content}</>;
};

export default SafeHtmlParser;
